<!--  -->
<template>
  <div class="myOrder card">
    <div class="btt">
      <div class="btts"><span></span>租赁订单信息</div>
    </div>
    <div class="myOrder-input">
      <div class="picker">

        <el-date-picker
          v-model="value1"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="mini"
          @change="getTime"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
      <div class="inputs">
        <el-input
          v-model.trim="input"
          placeholder="订单号"
          size="mini"
        ></el-input>
      </div>
      <el-button size="mini" type="primary" @click="toSelsect">搜索</el-button>
    </div>
    <div class="title">
      <ul>
        <li
          v-for="(item, index) in title"
          :key="index"
          :class="{ actives: current == index }"
          @click="checkoutActive(index)"
        >
          {{ item }}
        </li>
      </ul>
    </div>
    <div class="contaner">
      <div class="contaner-info">
        <div class="info-left">商品信息</div>
        <div class="info-right">
          <div class="info-left">商品价格</div>
          <div class="info-left">租赁时长(小时)</div>
          <div class="info-left">实付金额</div>
          <div class="info-left">订单状态</div>
          <div class="info-left">订单操作</div>
        </div>
      </div>
      <div class="contaner-color">
        <div class="color-left">
          <img src="../../assets/member/xxtx.png" alt="" />
          <p>使用上号器上号才能登录您租赁的账号!</p>
          <span @click="$router.push('/download')">立即免费下载</span>
        </div>
        <div class="color-right">x</div>
      </div>
      <div v-for="item in shopAllList" :key="item.id">
            <div class="contaner-price">
              <div>
                <span class="spans">订单号：{{item.order_no}}</span>
                <span class="price-span">创建时间：{{item.created_at}}</span>
              </div>
              <div>
                <span>租赁时间：{{item.created_at}} 至 {{item.end_time}}</span>
              </div>
            </div>
            <div class="contaner-boder">
              <div class="contaner-box">
                <div class="bottom-img">
                  <img :src="item.goods_info.game_img ||item.goods_info.data.list[0].game_img" alt="" />
                  <div>
                    <p>
                      {{item.goods_info.goods_title || item.goods_info.data.list[0].pn}}
                    </p>
                    <span class="comColor"> {{item.goods_info.game_all_name ||`${item.goods_info.data.list[0].game_name}-${item.goods_info.data.list[0].game_server_name}-${item.goods_info.data.list[0].game_zone_name}`}} </span>
                  </div>
                </div>
                <div class="bottom-txt">{{item.goods_info.hour_lease||item.goods_info.data.list[0].pmoney}}元/小时</div>
                <div class="bottom-txt">{{item.game_time||item.goods_info.data.list[0].szq}}小时</div>
                <div class="bottom-txt txt_active">¥{{item.pay_amount}}</div>
                <div class="bottom-txt txt_active">{{item.status_name}}</div>
                <div class="bottom-btn">
                  <div class="btns btn_active"  @click="$router.push({path: `myorder/orderdetail/${item.order_no}`})">
                    订单详情
                  </div>
                  <span  @click="$router.push({path: `/member/myorder/appeal/${item.order_no}`})" v-if="item.status == 2">申请维权</span>
                  <span  @click="$router.push({path: `/member/myorder/appeal/${item.order_no}`})" v-if="item.status > 3">维权记录</span>
                  <span v-if="item.new_channel==1" @click="$router.push({path: `/order/${item.goods_id}`})">立即续租</span>
                  <span v-if="item.new_channel==2" @click="clickOpen(item)">立即续租</span>
                </div>
              </div>
            </div>
      </div>
      <div class="color_value" v-if="ifShow">
        <p>您还没有订单!赶快去下单吧!</p>
      </div>
    </div>
    <!-- 分页器 -->
    <div class="paging">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        layout="prev, pager, next,total, jumper"
        :total="count"
        background
      ></el-pagination>
    </div>
  </div>
</template>
 
<script>

import qs from 'qs' 
export default {
  name: "myOrder",
  data() {
    return {
      order_no: '',
      start_time: '',

      ifShow: false,
      page: 1, //页码
      pageSize: 3, // 每页条数
      currentPage: 1, //分页器当前页
      count:0,
      //订单title
      current: 0,
      title: ["全部订单", "进行中订单", "维权订单", "已完成订单"],
      currentActive: ["all", "order_in", "refund", "end"],
      shopAllList: [],
      input: "",
      value1: "",
    };
  },
  created() {
    this.getShopList();
  },
  mounted() {
  },
  methods: {
      getTime() {
        this.start_time = this.value1[0] +','+this.value1[1]
      },
      toSelsect() {
        this.order_no = this.input
        this.page= 1
        this.getShopList()
        this.value1 = ''
        this.input = ''
        this.start_time = ''
      },
    //分页器设置
    handleSizeChange(val) {
       console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.page =val
       this.getShopList();
    },
    checkoutActive(index) {
      this.current = index;
      this.currentPage = 1
      this.getShopList();
    },
    getShopList() {
      var that = this;
      let user_id = this.$store.state.user_id;
      that
        .$get("api/order/list", {
          user_id,
          type: that.currentActive[that.current],
          page: that.page,
          page_size:that.pageSize,
          order_no: that.order_no,
          start_time: that.start_time
        })
        .then((res) => {
          console.log(res,123456);
          if (res.code == 0) {
            console.log(res,444);
              that.shopAllList = res.ret;
              if(res.ret.length != []){
                that.count =res.ret[0].count
                that.ifShow = false
              }else{
                that.ifShow = true
              }
          }
        });
    },


    clickOpen(item){
      this.$router.push({path: `/order/${qs.stringify(item.goods_info.data.list[0])}`})
    }
  },
};
</script>
 
<style lang='scss' scoped >
.myOrder {
  width: 968px;
  float: right;
  .btt {
    width: 100%;
    height: 65px;
    padding: 25px 35px 0;
    .btts {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 700;
      span {
        display: inline-block;
        width: 4px;
        height: 16px;
        background: #3c7efe;
        border-radius: 2px;
        margin-right: 10px;
      }
    }
  }
  .myOrder-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 50px 35px 60px;
    .inputs {
      width: 50%;
    }
  }
  .title {
    margin: 0 35px 0;
    ul {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #83899d;
      li {
        margin-right: 53px;
        height: 35px;
      }
    }
  }
  .contaner {
    margin: 0 35px 0;
    .contaner-info {
      width: 894px;
      height: 42px;
      background: #e7e9ec;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 20px;
      margin-top: 16px;
      .info-left {
        width: 96px;
        font-size: 14px;
        color: #848a9e;
      }
      .info-right {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 530px;
      }
    }
    .contaner-color {
      width: 894px;
      height: 32px;
      background: #fdecd3;
      opacity: 0.5;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px;
      margin-top: 10px;
      .color-left {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        p {
          margin-left: 10px;
          color: #83899d;
        }
        span {
          color: #ff9e00;
          margin-left: 10px;
          cursor: pointer;
        }
      }
      .color-right {
        color: #f8b551;
      }
    }
    .color_value{
      height: 240px;
      text-align: center;
      line-height: 240px;
      color: #000;
      font-weight:bold;
      font-size: 24px;
    }
    .contaner-price {
      width: 896px;
      height: 38px;
      background: #f6f7fa;
      border-radius: 4px 4px 0px 0px;
      font-size: 12px;
      color: #83899d;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      margin-top: 10px;
      .spans{
        user-select:text;
      }
      .price-span {
        margin-left: 50px;
      }
    }
    .contaner-boder {
      border: 1px solid #f6f7fa;
    }
    .bottom-info {
      width: 100%;
      height: 50px;
      border-radius: 12px 12px 0px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 20px;
      .info-left {
        width: 60px;
        font-size: 14px;
        color: #848a9e;
      }
      .info-right {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 500px;
      }
    }
    .contaner-box {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 20px 0;
      width: 894px;
      .bottom-img {
        display: flex;
        align-items: center;
        width: 310px;
        justify-content: space-between;
        img {
          width: 80px;
          height: 80px;
          border-radius: 12px;
        }
        p {
          width: 210px;
          height: 60px;
          font-size: 14px;
          font-weight: bold;
          color: #000000;
          line-height: 30px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      .bottom-txt {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #848a9e;
      }
      .bottom-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        .btns {
          width: 88px;
          height: 30px;
          border: 1px solid #c3c7d4;
          border-radius: 15px;
          text-align: center;
          line-height: 30px;
        }
        span {
          margin-top: 5px;
          color: #3c7efe;
        }

        .btn_active {
          background: #3c7efe;
          border: 1px solid #3c7efe;
          color: #fff;
        }
      }
    }
  }
}
//
.card {
  width: 100%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ebeef5;
  background-color: #fff;
  transition: 0.3s;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 10px;
}
.actives {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000;
  border-bottom: 2px solid #3c7efe;
}
.active {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #fff;
  border-bottom: 2px solid #3c7efe;
}
.txt_active {
  padding-left: 20px;
}

//分页器设置
.paging {
  padding: 30px 10px;
}
</style>
<style >
.el-pagination.is-background .el-pager li:not(.disabled) {
  border-radius: 15px;
  margin: 0px 8px;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  
  color: #fff;
}
.el-pagination.is-background .el-pager li.active {
  color: #fff !important;
 background-color: #3c7efe;

  cursor: default;
}
.el-pagination.is-background .el-pager li:hover {
  color: #3c7efe;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #3c7efe;
}
.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  color: #fff;
}
.el-pagination.is-background .el-pagination__total {
  margin-left: 25%;
}
</style>